export default function sumFilter(value) {

  if (!value && value !== 0) {
    return ''
  }

  if (value.toString().length <= 3) return value.toString()

  let i = 1
  return value.toString()
    .split('')
    .reverse()
    .map(number => {
      if (i % 3 === 0) {
        i++
        return ` ${number}`
      } else {
        i++
        return number
      }
    })
    .reverse()
    .join('')
}
