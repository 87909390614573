export default function dateFilter(value, format = 'date') {

  if (!value) {
    return `${value}`
  }

  if (format === 'ISO') {
    const date = new Date(value)
     return (`${date.getFullYear()}-`
          + `${(date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-`
          + `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`)
  }

  const options = {}
  if (format.includes('date')){
    options.day = '2-digit'
    options.month = 'long'
    options.year = 'numeric'
  }
  if (format.includes('time')) {
    options.hour = '2-digit'
    options.minute = '2-digit'
    options.second = '2-digit'
  }
  return new Intl.DateTimeFormat('ru-Ru', options).format(new Date(value))
}
