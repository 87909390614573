export default function phoneFilter(phoneNumber) {

  let phone = phoneNumber
  let regex = /^\+\d*$/

  if (phone.length) {
    if (phone.startsWith("9")) phone = `+7${phone}`
    else if (phone.startsWith("8")) phone = phone.replace("8", "+7")
    else if (phone.startsWith("+8")) phone = phone.replace("+8", "+7")
    else if (phone.startsWith("7")) phone = phone.replace("7", "+7")
    else if (phone.startsWith("+")) {
      if (phone.length >= 3) {
        if (phone.startsWith("+79")) null
        else phone = ""
      }
    } else phone = ""
  }

  if (!regex.test(phone)) {
    phone = ""
  }

  return phone
}
