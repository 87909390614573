export default {
  state: {
    toMaster: true,
    toClients: true,
  },
  mutations: {
    setToMaster(state, toMaster) {
      state.toMaster = toMaster
    },
    setToClients(state, ToClients) {
      state.ToClients = ToClients
    },
  },
  actions: {},
  getters: {
    toMaster: (state) => state.toMaster,
    toClients: (state) => state.toClients,
  }
}
