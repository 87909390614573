<template>
  <v-list dense>
    <v-list-item link to="/events">
      <v-list-item-icon>
        <v-badge v-if="qty" :content="qty" overlap color="light-green accent-4">
          <v-icon>{{iconRing}}</v-icon>
        </v-badge>
        <v-icon v-else>{{icon}}</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title >

        </v-list-item-title>
        <v-list-item-title >События</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import {mapGetters} from "vuex"

export default {
  name: "EventsRef",
  data: () => ({
    icon: "mdi-bell",
    iconRing: "mdi-bell-ring",
    interval: null
  }),
  computed: {
    ...mapGetters(['qty']),
  },
  methods: {
    async loadEventsQty() {
      this.loading = true
      const json = await this.authFetch('/event/load-new-events-qty', {}, false)
      this.$store.commit('setQty', json.qty)
      this.loading = false
    }
  },
  mounted() {
    this.loadEventsQty()
    this.interval = setInterval(() => {
      if (this.$route.path !== "/events") this.loadEventsQty()
    }, 10000)
  },
  beforeDestroy() {
    if (this.interval) clearInterval(this.interval)
  }
}

</script>

<style scoped>

</style>
