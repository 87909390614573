export default {
  // eslint-disable-next-line no-unused-vars
  install(Vue, options) {
    Vue.prototype.authFetch = async function (url, payload = {}, serverMessage = true) {
      const token = JSON.parse(localStorage.getItem("token"))

      const res = await fetch(`${this.$store.state.server}${url}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${token}`
        },
        body: JSON.stringify({...payload})
      })

      const json = await res.json()
      json.status = res.status
      if (json.message && serverMessage) this.$store.commit('setServerMessage', json.message)
      return json
    }

    Vue.prototype.authFetchFormData = async function (url, formData, serverMessage = true) {
      if (!formData) {
        return
      }
      const token = JSON.parse(localStorage.getItem("token"))

      const res = await fetch(`${this.$store.state.server}${url}`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${token}`
        },
        body: formData
      })

      const json = await res.json()
      json.status = res.status
      if (json.message && serverMessage) this.$store.commit('setServerMessage', json.message)
      return json
    }

    Vue.prototype.sortRecordListFunc = function (r1, r2) {
      if (r1.date < r2.date) return -1
      if (r1.date > r2.date) return 1
      if (r1.date === r2.date) {
        if (r1.time < r2.time) return -1
        if (r1.time > r2.time) return 1
        return 0
      }
    }

    Vue.prototype.sortReversRecordListFunc = function (r1, r2) {
      if (r1.date > r2.date) return -1
      if (r1.date < r2.date) return 1
      if (r1.date === r2.date) {
        if (r1.time > r2.time) return -1
        if (r1.time < r2.time) return 1
        return 0
      }
    }

    Vue.prototype.sortPaysFunc = function (p1, p2) {
      if (p1.date > p2.date) return -1
      if (p1.date < p2.date) return 1
      if (p1.date === p2.date) {
        if (p1.registerDate > p2.registerDate) return -1
        if (p1.registerDate < p2.registerDate) return 1
      }
    }

    Vue.prototype.sortClientListFunc = function (r1, r2) {
      if (r1.lastName < r2.lastName) return -1
      if (r1.lastName > r2.lastName) return 1
      if (r1.lastName === r2.lastName) {
        if (r1.firstName < r2.firstName) return -1
        if (r1.firstName > r2.firstName) return 1
        return 0
      }
    }

    Vue.prototype.sortEventsFunc = function (r1, r2) {
      if (r1.eventDay > r2.eventDay) return -1
      if (r1.eventDay < r2.eventDay) return 1
      if (r1.eventDay === r2.eventDay) {
        if (r1.eventTime > r2.eventTime) return -1
        if (r1.eventTime < r2.eventTime) return 1
        if (r1.eventTime === r2.eventTime) {
          if (r1.event && !r2.event) return 1
          if (!r1.event && r2.event) return -1
        }
        return 0
      }
    }

    Vue.prototype.sortMessagesFunc = function (r1, r2) {
      if (r1.message.create_date > r2.message.create_date) return -1
      if (r1.message.create_date < r2.message.create_date) return 1
      if (r1.message.create_date === r2.message.create_date) {
        if (r1.send_date > r2.send_date) return -1
        if (r1.send_date < r2.send_date) return 1
        return 0
      }
    }

    Vue.prototype.createRecaptchaScript = function() {
      if (document.querySelector('#recaptchaScript')) {
        document.querySelector('#recaptchaScript').remove()
      }
      const script = document.createElement("script")
      script.src = "https://www.google.com/recaptcha/api.js"
      script.async = true
      script.defer = true
      script.id = "recaptchaScript"
      document.head.appendChild(script)
    }

    Vue.prototype.checkIncludesAndAdd_notForObjects = function(toArray, fromArray) {
      for(let el of fromArray) {
        if (!toArray.includes(el)) toArray.push(el)
      }
    }

    Vue.prototype.checkIncludesAndAdd_forArraysOfSimpleObjects = function(toArray, fromArray) {
      const toArrayOfStringifiedObjects = toArray.map(object => {
        return JSON.stringify(object)
      })

      for(let el of fromArray) {
        if (!toArrayOfStringifiedObjects.includes(JSON.stringify(el))) toArray.push(el)
      }
    }
  }
}
