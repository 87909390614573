import Vue from 'vue'
import Vuex from 'vuex'
import currentRecord from "./modules/currentRecord"
import smsSettings from "./modules/smsSettings"
import events from "./modules/events"
import idbActions from "./modules/idbActions"
import master from "./modules/master"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    authenticated: false,
    clientAuth: false,
    server: process.env.NODE_ENV === "production"
    ?"https://elenanovikova.ru/api"
    :"http://localhost:5000/api",
    serverMessage: ""
  },
  mutations: {
    setServerMessage(state, serverMessage) {
      state.serverMessage = serverMessage
    },
    setAuthenticated(state, bool) {
      state.authenticated = bool
    },
    setClientAuth(state, bool) {
      state.clientAuth = bool
    }
  },
  actions: {
  },
  modules: {
    currentRecord,
    smsSettings,
    idbActions,
    events,
    master
  }
})
