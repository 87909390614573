export default {
  state: {
    qty: 0,
  },
  mutations: {
    setQty(state, toMaster) {
      state.qty = toMaster
    },
  },
  actions: {},
  getters: {
    qty: (state) => state.qty,
  }
}
