<template>
<!--    <div-->
<!--      v-if="snackbar"-->
<!--      id="cookiesMessage"-->
<!--      :style="`position: absolute;-->
<!--               top: ${messageTopPosition}px;-->
<!--               z-index: 100;-->
<!--               padding: 10px;-->
<!--    `"-->
<!--    >   -->
      <div
      v-if="snackbar"
      id="cookiesMessage"
      :style="`position: fixed;
               bottom: 0px;
               width: 100%;
               z-index: 100;
               padding: 10px;
    `"
    >
      <v-banner
        color="deep-purple lighten-5"
        rounded
        style="color: rebeccapurple"
      >
        {{ text }}
        <template v-slot:actions>

          <v-btn
            color="deep-purple darken-3"
            outlined
            rounded
            @click="rememberNotification"
          >
            {{ answer }}
          </v-btn>
        </template>
      </v-banner>

    </div>

</template>

<script>
export default {
  name: "CookiesMessage",
  data: () => ({
    snackbar: false,
    text: `Мы должны Вас уведомить о том, что для работы нашего
    сайта мы используем файлы Cookies, а также сторонние сервисы, такие как
    Google reCaptcha, сервисы звонков, СМС-сообщений и иные библиотеки`,
    vertical: true,
    multiLine: true,
    answer: 'Хорошо, я поняла',
    interval: null,
    windowHeight: 0,
    messageHeight: 0,
    messageTopPosition: 0
  }),

  // computed: {
  //   windowHeight() {
  //     console.log(window.innerHeight, window.scrollY)
  //     return window.innerHeight + window.scrollY
  //   },
  //
  //   messageHeight() {
  //     if (!document.querySelector('#cookiesMessage')) {
  //       return 0
  //     }
  //     return document.querySelector('#cookiesMessage').clientHeight
  //   },
  //
  //   messageTopPosition() {
  //     return this.windowHeight - this.messageHeight
  //   }
  // },

  methods: {
    rememberNotification() {
      localStorage.setItem('notifiedAboutCookies', 'true')
      clearInterval(this.interval)
      this.snackbar = false
    },

    setAnswer() {
      const random = Math.floor(Math.random() * 3);
      switch (random) {
        case 0:
          this.answer = "Хорошо, я поняла"
          break
        case 1:
          this.answer = "Ну, ладно..."
          break
        case 2:
          this.answer = "Окей, окей..."
          break
      }
    },
  },

  mounted() {
    if (!localStorage.getItem('notifiedAboutCookies')) {
      this.setAnswer()
      this.interval = setInterval(() => {
        this.windowHeight = window.innerHeight + window.scrollY
        this.messageHeight = document.querySelector('#cookiesMessage').clientHeight
        this.messageTopPosition = this.windowHeight - this.messageHeight
      }, 0)
      this.snackbar = true
    }
  }
}


</script>

<style scoped>

</style>
