import Vue from "vue"
import VueRouter from "vue-router"
import store from "../store/index"

Vue.use(VueRouter)

const landingRoutes = [
  {
    path: "/",
    name: "Landing",
    meta: {auth: false},
    component: () => import("../views/landing/Landing.vue")
  },
  {
    path: "/price-list",
    name: "PriceList",
    meta: {auth: false},
    component: () => import("../views/landing/PriceList.vue")
  },
  {
    path: "/choose-category",
    name: "ChooseCategory",
    meta: {auth: false},
    component: () => import("../views/landing/ChooseCategory.vue")
  },
  {
    path: "/choose-service",
    name: "ChooseService",
    meta: {auth: false},
    component: () => import("../views/landing/ChooseService.vue")
  },
  {
    path: "/choose-datetime",
    name: "ChooseDateTime",
    meta: {auth: false},
    component: () => import("../views/landing/ChooseDateTime.vue")
  },
  {
    path: "/introduce_your_self",
    name: "Introduce",
    meta: {auth: false},
    component: () => import("../views/landing/Introduce.vue")
  },
  {
    path: "/about",
    name: "About",
    meta: {auth: false},
    component: () => import("../views/landing/About.vue")
  },
  {
    path: "/master-info",
    name: "MasterInfo",
    meta: {auth: false},
    component: () => import("../views/landing/MasterInfo.vue")
  },
  {
    path: "/update-sw",
    name: "UpdateServiceWorkers",
    meta: {auth: false},
    component: () => import("../views/landing/UpdateServiceWorkers.vue")
  },

]

const clientRoutes = [
  {
    path: "/client-records",
    name: "ClientRecords",
    meta: {auth: true, type: "client"},
    component: () => import("../views/client/ClientRecords.vue")
  },
  {
    path: "/clients_login",
    name: "LoginClient",
    meta: {auth: false},
    component: () => import("../views/client/ClientLogin.vue")
  },
]

const authRoutes = [
  {
    path: "/login",
    name: "Login",
    meta: {auth: false, type: "user"},
    component: () => import("../views/auth/Login.vue")
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   meta: {auth: process.env.NODE_ENV === "production", type: "user"},
  //   component: () => import("../views/auth/Register.vue")
  // },
]

const masterRoutes = [
  {
    path: "/master-settings",
    name: "MasterSettings",
    meta: {auth: true, type: "user"},
    component: () => import("../views/master/MasterSettings.vue")
  },
  {
    path: "/notifications-for-clients",
    name: "NotificationsForClients",
    meta: {auth: true, type: "user"},
    component: () => import("../views/master/NotificationsForClients.vue")
  },
  {
    path: "/calendar",
    name: "Calendar",
    meta: {auth: true, type: "user"},
    component: () => import("../views/master/Calendar.vue")
  },
  {
    path: "/tomorrow-records",
    name: "TomorrowRecords",
    meta: {auth: true, type: "user"},
    component: () => import("../views/master/TomorrowRecords.vue")
  },
  {
    path: "/today-records",
    name: "TodayRecords",
    meta: {auth: true, type: "user"},
    component: () => import("../views/master/TodayRecords.vue")
  },
  {
    path: "/categories",
    name: "Categories",
    meta: {auth: true, type: "user"},
    component: () => import("../views/master/Categories.vue")
  },
  {
    path: "/services",
    name: "Services",
    meta: {auth: true, type: "user"},
    component: () => import("../views/master/Services.vue")
  },
  {
    path: "/choose-category-master",
    name: "ChooseCategoryMaster",
    meta: {auth: true, type: "user"},
    component: () => import("../views/master/ChooseCategoryMaster.vue")
  },
  {
    path: "/choose-service-master",
    name: "ChooseServiceMaster",
    meta: {auth: true, type: "user"},
    component: () => import("../views/master/ChooseServiceMaster.vue")
  },
  {
    path: "/choose-datetime-master",
    name: "ChooseDateTimeMaster",
    meta: {auth: true, type: "user"},
    component: () => import("../views/master/ChooseDateTimeMaster.vue")
  },
  {
    path: "/introduce-master",
    name: "IntroduceMaster",
    meta: {auth: true, type: "user"},
    component: () => import("../views/master/IntroduceMaster.vue")
  },
  {
    path: "/exchange-records",
    name: "ExchangeRecords",
    meta: {auth: true, type: "user"},
    component: () => import("../views/master/ExchangeRecords.vue")
  },
  {
    path: "/clients",
    name: "Clients",
    meta: {auth: true, type: "user"},
    component: () => import("../views/master/Clients.vue")
  },
  {
    path: "/records-of-client",
    name: "RecordsOfClient",
    meta: {auth: true, type: "user"},
    component: () => import("../views/master/RecordsOfClient.vue")
  },
  {
    path: "/black-list",
    name: "BlackList",
    meta: {auth: true, type: "user"},
    component: () => import("../views/master/BlackList.vue")
  },
  {
    path: "/events",
    name: "Events",
    meta: {auth: true, type: "user"},
    component: () => import("../views/master/Events.vue")
  },
  {
    path: "/payments",
    name: "Payments",
    meta: {auth: true, type: "user"},
    component: () => import("../views/master/Payments.vue")
  },


]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [...landingRoutes, ...clientRoutes, ...authRoutes, ...masterRoutes],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  }
})

router.beforeEach(async (to, from, next) => {
  try{
    let authTypeMatching = false
    let requireAuth = to.meta.auth
    let requiredType
    if (requireAuth) requiredType = to.meta.type

    const token = JSON.parse(localStorage.getItem("token"))

    let type

    if (token) {
      let response = await fetch(`${store.state.server}/auth/verify`, {
        method: "POST",
        headers: {
          "Content-type": "application/json"
        },
        body: JSON.stringify({token})
      })

      if (response.status === 200) {
        let json = await response.json()

        type = json.type
        if (requireAuth && type === requiredType) {
          authTypeMatching = true
        }

        if (type === "user") {
          store.commit("setAuthenticated", true)
          store.commit("setClientAuth", false)
        }

        if (type === "client") {
          store.commit("setAuthenticated", false)
          store.commit("setClientAuth", true)
          localStorage.setItem('firstName', JSON.stringify(json.firstName))
          store.commit("setFirstName", json.firstName)
          localStorage.setItem('lastName', JSON.stringify(json.lastName))
          store.commit("setLastName", json.lastName)
          localStorage.setItem('phone', JSON.stringify(json.phone))
          store.commit("setPhone", json.phone)
        }

      } else {
        localStorage.removeItem('token')
        localStorage.removeItem('phone')
        localStorage.removeItem('firstName')
        localStorage.removeItem('lastName')
        store.commit("setAuthenticated", false)
        store.commit("setClientAuth", false)
      }
    }

    if (requireAuth && !authTypeMatching) {
      if (requiredType === "client") {
        next("/clients_login")
      }

      if (requiredType === "user") {
        next("/login")
      }

    } else {
      next()
    }
  } catch (e) {
    console.warn(e)
    next('/')
  }

})

export default router
