export default {
  state: {},
  mutations: {},
  actions: {
    writeToiDB(state, [key, value]) {
      let openRequest = indexedDB.open('iDB', 1)

      openRequest.onupgradeneeded = function () {
        let db = openRequest.result
        if (!db.objectStoreNames.contains('store')) {
          db.createObjectStore('store')
        }
      }

      openRequest.onsuccess = function () {
        let db = openRequest.result
        let transaction = db.transaction("store", "readwrite")
        let store = transaction.objectStore("store")
        store.put(value, key)
        // продолжить работу с базой данных, используя объект db
      }

      openRequest.onerror = function () {
        console.error("Error", openRequest.error)
      }
    },

    getFromiDB(state, key) {
      return new Promise((resolve, reject) => {
        let openRequest = indexedDB.open('iDB', 1)

        openRequest.onupgradeneeded = function () {
          let db = openRequest.result
          if (!db.objectStoreNames.contains('store')) {
            db.createObjectStore('store')
          }
        }

        openRequest.onsuccess = function () {
          let db = openRequest.result
          let transaction = db.transaction("store", "readonly")
          let store = transaction.objectStore("store")
          store.get(key).onsuccess = response => {
            resolve(response.target.result)
          }
        }

        openRequest.onerror = function () {
          console.error("Error", openRequest.error)
          reject(openRequest.error)
        }
      })
    },
  },
  getters: {}
}
