<template>
  <v-app>
    <v-main>

      <CookiesMessage v-if="mountedFlag"/>

      <v-container
        class="flex-container-column"
        :style="{minHeight: `${this.$route.meta.type === 'user' ? 'calc(100% - 57px)' : 'calc(100% - 180px)'}`}"
      >

        <v-app-bar absolute :color="theme" class="mb-4">

          <v-app-bar-nav-icon
            @click.stop="drawer = !drawer"
            class="white--text">
            <v-icon v-if="!drawer">mdi-menu</v-icon>
            <v-icon v-if="drawer">mdi-backburger</v-icon>
          </v-app-bar-nav-icon>

          <v-toolbar-title v-if="typeUser" class="white--text">
            Мастерская
            <v-btn v-if="qty && authenticated" icon depressed color="lime accent-3" to="/events">
              <v-icon>mdi-bell-ring</v-icon>
            </v-btn>
          </v-toolbar-title>

          <v-toolbar-title v-if="!typeUser && !clientAuth" class="white--text">
            Мастер Елена Новикова
          </v-toolbar-title>

          <v-toolbar-title v-if="!typeUser && clientAuth" class="white--text subtitle-2">
            Вы вошли, как
            <router-link to="/client-records" class="white--text"><u>{{ firstName }} {{ lastName }}</u></router-link>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn v-if="authenticated || clientAuth" @click="exitDialog = true" fab dark small elevation="0"
                 :color="theme">
            <v-icon color="white">mdi-logout</v-icon>
          </v-btn>

          <v-dialog
            v-model="exitDialog"
            max-width="290"
          >
            <v-card>
              <v-card-title class="text-h5">
              </v-card-title>

              <v-card-text class="text-h5">
                Вы действительно хотите выйти?
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="red darken-1" text @click="exitDialog = false">Отмена</v-btn>

                <v-btn color="green darken-1" text @click="exit()">Да</v-btn>

              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-app-bar>

        <router-view style="margin-top: 70px"></router-view>

        <v-snackbar
          v-model="snackbar"
          :timeout="5000"
          centered
        >
          {{ message }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="blue"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Ok
            </v-btn>
          </template>
        </v-snackbar>

        <v-navigation-drawer
          v-model="drawer"
          app
        >
          <v-list-item>
            <v-list-item-avatar v-if="authenticated">
              <v-img src="/img/cardImage1.jpg"></v-img>
            </v-list-item-avatar>

            <v-list-item-content v-if="authenticated">
              <v-list-item-title>
                Мастер:
                <br>
                Елена Новикова
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-content v-if="!authenticated">
              <v-list-item-title>
                Ваш мастер:
                <br>
                Елена Новикова
              </v-list-item-title>
              <v-list-item-subtitle>
                <br>
                <v-btn fab small elevation="0" href="tel:+79042280684" style="text-decoration: none">
                  <v-icon color="deep-purple lighten-1">mdi-phone</v-icon>
                </v-btn>
                +79042280684
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-btn
              v-if="authenticated || clientAuth"
              @click="exitDialog = true"
              :small="authenticated"
              fab
              elevation="0"
              class="transparent"
            >
              <v-icon
                :large="!authenticated"
                :color="authenticated ? 'teal lighten-1' : 'deep-purple lighten-1'"
              >
                mdi-logout
              </v-icon>
            </v-btn>

          </v-list-item>

          <v-divider v-if="authenticated"></v-divider>
          <v-list dense v-if="authenticated">
            <v-list-item link to="/master-settings">
              <v-list-item-icon>
                <v-icon>mdi-account-cog</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Настройки</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider v-if="authenticated"></v-divider>
          <EventsRef v-if="authenticated"></EventsRef>

          <v-list dense v-if="authenticated">
            <v-list-item link to="/notifications-for-clients">
              <v-list-item-icon>
                <v-icon>mdi-message-badge</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Сообщения</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list dense v-if="authenticated">
            <v-list-item v-for="ref in masterRefList" :key="ref.id" link :to="ref.link">
              <v-list-item-icon>
                <v-icon>{{ ref.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ ref.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>


          </v-list>

          <v-divider v-if="authenticated"></v-divider>

          <v-list dense v-if="!authenticated">
            <v-list-item link to="/calendar">
              <v-list-item-icon>
                <v-icon>mdi-cog</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Мастерская</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider v-if="!authenticated"></v-divider>

          <v-list dense>
            <v-list-item v-for="ref in landingRefList" :key="ref.id" link :to="ref.link">
              <v-list-item-icon>
                <v-icon>{{ ref.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ ref.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>

      </v-container>

      <v-footer dark padless>
        <v-card flat tile class="white--text text-center" :class="theme" :width="'100%'">
          <!--          <v-card-text>-->
          <!--            <v-btn v-for="icon in footerIcons" :key="icon" class="mx-4 white&#45;&#45;text" icon>-->
          <!--              <v-icon size="24px">-->
          <!--                {{ icon }}-->
          <!--              </v-icon>-->
          <!--            </v-btn>-->
          <!--          </v-card-text>-->

          <v-card-text id="address" v-if="!authenticated || !typeUser" class="white--text">
            Мастер Елена Новикова
            <br>
            <Address
              :official="false"
            />
          </v-card-text>

          <v-divider></v-divider>

          <small>v 25072024</small>

          <v-card-text class="white--text">
            <v-icon>mdi-copyright</v-icon>
            <strong> iVanR</strong> 2020
          </v-card-text>

        </v-card>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
import dateFilter from "./filters/dateFilter"
import {mapState, mapGetters} from "vuex"
import EventsRef from "@/components/EventsRef"
import Address from "@/components/Address"
import CookiesMessage from "@/components/CookiesMessage"

import {onMessage} from "firebase/messaging"

export default {
  name: 'App',

  components: {CookiesMessage, Address, EventsRef},

  data: () => ({
    drawer: false,
    snackbar: false,
    message: '',
    exitDialog: false,
    loadMessagesSettings: false,
    footerIcons: [
      'mdi-message-bulleted-off',
      'mdi-twitter',
    ],

    masterRefList: [
      {id: 1, text: "Календарь", link: "/calendar", icon: "mdi-calendar"},
      {id: 2, text: "Мои услуги", link: "/categories", icon: "mdi-briefcase"},
      {id: 3, text: "Мои процедуры и цены", link: "/services", icon: "mdi-format-list-numbered"},
      {id: 4, text: "Мои клиенты", link: "/clients", icon: "mdi-account-multiple"},
      {id: 5, text: "Черный список", link: "/black-list", icon: "mdi-account-off"},
      {id: 6, text: "Платежи и расходы", link: "/payments", icon: "mdi-currency-rub"}
    ],
    windowHeight: 0,
    documentHeight: 0,
    bodyHeight: 0,
    documentRectHeight: 0,
    visibleHeight: 0,
    mountedFlag: false
  }),

  computed: {
    ...mapState(['serverMessage', 'authenticated', 'clientAuth']),
    ...mapGetters(['qty', 'firstName', 'lastName']),

    landingRefList() {
      if (this.authenticated) {
        return [
          {id: 1, text: "Лендинг", link: "/", icon: "mdi-island"},
          {id: 2, text: "О приложении", link: "/about", icon: "mdi-information-outline"}
        ]
      } else {
        return [
          {id: 1, text: "На главную", link: "/", icon: "mdi-home"},
          {id: 2, text: "О приложении", link: "/about", icon: "mdi-information-outline"}
        ]
      }
    },

    theme() {
      if (this.$route.meta.type === 'user') {
        return 'teal lighten-1'
      } else {
        return 'deep-purple lighten-2'
      }
    },

    typeUser() {
      return this.$route.meta.type === 'user'
    },

    wHeight() {
      return window.screen.height
    },

    wWidth() {
      return window.screen.width
    },

  },

  methods: {
    loginHandler() {
      this.$router.push('/calendar')
    },

    dateFilter(value, format = 'ISO') {
      return dateFilter(value, format)
    },

    exit() {
      localStorage.removeItem('token')
      localStorage.removeItem('phone')
      localStorage.removeItem('firstName')
      localStorage.removeItem('lastName')
      this.$store.commit("setAuthenticated", false)
      this.$store.commit("setClientAuth", false)
      this.$router.push("/")
      this.exitDialog = false
    }
  },

  mounted() {
    this.mountedFlag = true
    onMessage(this.firebaseMessaging, async (payload) => {
      try {
        const _id = payload.data._id
        const message_title = payload.data.message_title
        const message_text = payload.data.message_text
        const delivery_confirm_route = payload.data.delivery_confirm_route

        const notificationOptions = {
          body: message_text,
          icon: `${delivery_confirm_route.split("/")[0] + '//' + delivery_confirm_route.split("/")[2]}/favicon.ico`,
        }

        new Notification(message_title, notificationOptions)

        // console.log('FCM SW: перед регистрацией доставки имеем параметры:', {_id, provider, message_kind})
        // eslint-disable-next-line
        const confirm_res = await fetch(delivery_confirm_route, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({_id})
        })

      } catch (error) {
        console.log('PUSH ServiceWorker ERROR', error.toString())
      }
    })

  },

  watch: {
    serverMessage: function (message) {
      if (message.length) {
        this.message = message
        this.snackbar = true
        this.$store.commit('setServerMessage', '')
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "~vuetify/dist/vuetify.min.css";
@import "./assets/css/style.css";
</style>
