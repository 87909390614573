<template>
  <div>
    <div v-if="!official">
      город Емва, улица
      Дзержинского 51, центральный вход, 1-ый этаж
      <br>
      (бывшее управление КМЗ)
      <br>
      прием осуществляется исключительно по предварительной записи
    </div>
    <div v-if="official">
      169200, Республика Коми, город Емва, улица Дзержинского 51, помещение 19.
      <br><br>
      Прием осуществляется исключительно по предварительной записи.
    </div>

  </div>
</template>

<script>
export default {
  name: "Address",
  props: {
    official: {
      type: Boolean,
      required: true
    }
  }
}
</script>
