export default {
  state: {
    loading: false,
    winsLoading: false,
    recordList: [],
    pays: [],
    loadedPastDates: [],
  },
  mutations: {
    addPays(state, pays) {
      if (!state.pays.length) {
        if (Array.isArray(pays)) state.pays = pays
        else state.pays.push(pays)
      } else {
        if (Array.isArray(pays)) state.pays.push(...pays)
        else state.pays.push(pays)
      }
      state.pays.sort(this.sortPaysFunc)
    },
  },
  actions: {},
  getters: {
    pays: (state) => state.pays
  }
}
