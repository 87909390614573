import Vue from "vue"
import App from "./App.vue"
import "./registerServiceWorker"
import router from "./router"
import store from "./store"
import vuetify from "./plugins/vuetify"
import date from "./filters/dateFilter"
import phoneFilter from "./filters/phoneFilter"
import functions from "@/plugins/functions"
import sumFilter from "@/filters/sumFilter"
import eventHub from "@/plugins/eventHub"
import firebasePlugin from "@/plugins/firebasePlugin"
import '@mdi/font/css/materialdesignicons.css'

Vue.config.productionTip = false
Vue.filter("date", date)
Vue.filter("sum", sumFilter)
Vue.filter("phone", phoneFilter)
Vue.use(functions)
Vue.use(eventHub)
Vue.use(firebasePlugin)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app")
