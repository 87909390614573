import {initializeApp} from "firebase/app"
import {getMessaging, getToken} from "firebase/messaging"

const firebaseConfig = {
  apiKey: "AIzaSyAOurX6dgJKAf4QWUse1Xe7sll7DSQHUG4",
  authDomain: "elenanovikovaru-68230.firebaseapp.com",
  projectId: "elenanovikovaru-68230",
  storageBucket: "elenanovikovaru-68230.appspot.com",
  messagingSenderId: "648196206668",
  appId: "1:648196206668:web:95f5d707e14797279504e9",
  measurementId: "G-NB9661FB45"
}

export default {
  // eslint-disable-next-line no-unused-vars
  install(Vue, options) {
    Vue.prototype.firebaseApp = initializeApp(firebaseConfig)

    Vue.prototype.firebaseMessaging = getMessaging(this.firebaseApp)

    let timeOut = 0

    Vue.prototype.firebaseGetToken = async function () {
      try {
        if ('serviceWorker' in navigator) {
          const registration = await navigator.serviceWorker.getRegistration('firebase-messaging-sw.js')
          if (registration.active.state === 'activated') {
            const token = await getToken(this.firebaseMessaging,
              {vapidKey: "BKO_CPqT8-by9q52vi62lm5mTLJPf16PfemSGOQkJ0DGfvGbY0K0WRO8yNlgTc6PtiaocZcex7Fsf2YebQa2iaY"})
            if (token) {
              return token
            } else {
              console.log('Токен не получен, кто знает почему...')
            }
          } else {
            timeOut += 1
            console.log('Сервис-воркер firebase-messaging-sw.js не зарегистрирован, ждем', timeOut)
            await new Promise(resolve => setTimeout(resolve, 1000)) // Ждем 1 секунду перед рекурсивным вызовом

            return await this.firebaseGetToken() // Рекурсивный вызов и возврат значения

          }
        }
      } catch (err) {
        console.log('Ошибка при получении токена плагином', err)
      }

    }
  }
}
