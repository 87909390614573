export default {
  state: {
    date: "",
    time: "",
    category: "",
    services: [],
    total: 0,
    firstName: "",
    lastName: "",
    phone: "",
    ownerId: process.env.NODE_ENV === "production"
      ? "5fb42e854cc3d808685fba86"
      : "5fb63e481ee31f1f6090d173",
    open: false,
  },
  mutations: {
    setDate(state, date) {
      state.date = date
    },
    setTime(state, time) {
      state.time = time
    },
    setCategory(state, category) {
      state.category = category
    },
    setServices(state, services) {
      state.services = services
    },
    setTotal(state, total) {
      state.total = total
    },
    setFirstName(state, firstName) {
      state.firstName = firstName
    },
    setLastName(state, lastName) {
      state.lastName = lastName
    },
    setPhone(state, phone) {
      state.phone = phone
    },
  },
  actions: {},
  getters: {
    date: (state) => state.date,
    time: (state) => state.time,
    category: (state) => state.category,
    services: (state) => state.services,
    total: (state) => state.total,
    firstName: (state) => state.firstName,
    lastName: (state) => state.lastName,
    phone: (state) => state.phone,
    ownerId: (state) => state.ownerId,
    open: (state) => state.open,
    ownerPhone: (state) => state.ownerPhone
  }
}
